<template>
  <div id="app">
    <v-app>
    <div style="margin-top:0; padding-top:0;">
    WS Conn: <span v-if="wsConnected == true" style="color:green;">OK</span> <span v-else style="color:red;">NOK</span>
    EMS Conn: <span v-if="this.edata.emsStatus == 'connected'" style="color:green;">OK</span> <span v-else style="color:red;">NOK</span>
    Rot Conn: <span v-if="this.edata.rotStatus == 'connected'" style="color:green;">OK</span> <span v-else style="color:red;">NOK</span>
  </div>
    <v-select
  label="Select Raw Stream (6,7=leg | 8,9=elb)"
  :items="['t6','t7','t8','t9']"
  v-model="rawselect"
></v-select>
<v-slider
    v-model="updateInterval"
    :max="500"
    :min="0"
    :step="10"
    hide-details
    class="align-center"
    thumb-label
    show-ticks
    :thumb-size="36"
    label="Upd Int"
    @change="sendCmd('setSendInterval', updateInterval)"
  >
  </v-slider>
  <hr/>
    <v-range-slider
    v-model="legThreshs"
    :max="2600"
    :min="0"
    :step="1"
    hide-details
    class="align-center"
    thumb-label
    :thumb-size="36"
    label="Leg Thr"
    @change="sendCmd('setLegStartThresh',legThreshs[0]); sendCmd('setLegMaxThresh',legThreshs[1])"
  >
  </v-range-slider>
  <v-range-slider
    v-model="elbThreshs"
    :max="2600"
    :min="0"
    :step="1"
    hide-details
    class="align-center"
    thumb-label
    :thumb-size="36"
    label="Elb Thr"
    @change="sendCmd('setElbowStartThresh',elbThreshs[0]); sendCmd('setElbowMaxThresh',elbThreshs[1])"
  >
  </v-range-slider>
  <v-slider
    v-model="legFilter"
    :max="1"
    :min="0"
    :step="0.01"
    hide-details
    class="align-center"
    thumb-label
    :thumb-size="36"
    label="Leg Filter"
    @change="sendCmd('setLegFilter',legFilter);"
  ></v-slider>
  <v-slider
    v-model="elbFilter"
    :max="1"
    :min="0"
    :step="0.01"
    hide-details
    class="align-center"
    thumb-label
    :thumb-size="36"
    label="Elb Filter"
    @change="sendCmd('setElbowFilter',elbFilter);"
  >
  </v-slider>
  <hr/>
  <plotly :data="pdata" :layout="layout"></plotly>

    </v-app>
  </div>
</template>

<script>
  import {
    Plotly
  } from 'vue-plotly'
  import axios from 'axios'

  export default {
    name: 'app',
    components: {
      Plotly
    },
    data() {
      return {
        target: 'localhost',
        edata: {},
        legThreshs: [200,490],
        elbThreshs: [200,490],
        updateInterval: 200,
        legFilter: 0.05,
        elbFilter: 0.05,
        rawselect: 't6',
        pdata: [{
          y: [],
          mode: 'lines',
          name: 'legT lo',
          line: {
            dash: 'dash',
            color: '#ffbbbb'
          }
        },{
          y: [],
          mode: 'lines',
          name: 'legT hi',
          line: {
            dash: 'dot',
            color: '#ffbbbb'
          }
        },{
          y: [],
          mode: 'lines',
          name: 'elbT lo',
          line: {
            dash: 'dash',
            color: '#bbffbb'
          }
        },{
          y: [],
          mode: 'lines',
          name: 'elbT hi',
          line: {
            dash: 'dot',
            color: '#bbffbb'
          }
        }, {
          y: [],
          mode: 'legSend',
          name: 'leg',
          line: {
            color: '#F033F6'
          }
        },{
          y: [],
          mode: 'elbSend',
          name: 'elb',
          line: {
            color: '#0503FF'
          }
        }, {
          y: [],
          mode: 'raw',
          name: 'raw',
          line: {
            color: '#aaaaaa'
          }
        }],
        layout: {          
          legend: {
            orientation: 'h',
          },
          margin: {
            l: 30,
            r: 0,
            b: 0,
            t: 30,
            pad: 0
          },
        },
        ws: undefined,
        wsUrl: 'ws://localhost:8080/ws',
        wsConnected: false,
        bleConnected: "unknown",
        firstLight: true,
      }
    },
    created() {
      //Prepare WS
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri);
      console.log(params.get("esp"));
      if (params.get("esp") != null) {
        this.target = params.get("esp");
        this.wsUrl = 'ws://' + params.get("esp") + '/wave';
      }

      //Prepare data arrays
      for (let i = 0; i < this.pdata.length; i++) {
        this.pdata[i].y = Array(250).fill(0)
      }
    },
    mounted() {
      //Connect WS
      this.ws = new WebSocket(this.wsUrl);
      this.ws.onopen = () => this.wsConnected = true;
      this.ws.onmessage = this.handleWs;      
    },
    methods: {
      handleWs(event) {        
        let data = JSON.parse(event.data);
        this.edata = data;
        console.log(data)

        //thresholds
        this.legThreshs = [parseInt(data.legStartThresh),parseInt(data.legMaxThresh)];
        this.elbThreshs = [parseInt(data.elbowStartThresh),parseInt(data.elbowMaxThresh)];

        //graph threshs
        this.pdata[0].y.fill(parseInt(data.legStartThresh+3))
        this.$set(this.pdata[0], 'y', this.pdata[0].y.slice(1)) //reactivity fix
        this.pdata[0].y.push(parseInt(data.legStartThresh+3)) //reactivity fix
        this.pdata[1].y.fill(parseInt(data.legMaxThresh+3))
        this.pdata[2].y.fill(parseInt(data.elbowStartThresh))
        this.pdata[3].y.fill(parseInt(data.elbowMaxThresh))

        //Processed data
        this.pdata[4].y.push(parseInt(data.legSend));
        this.pdata[4].y.shift();

        this.pdata[5].y.push(parseInt(data.elbowSend));
        this.pdata[5].y.shift();

        //Raw channel
        this.pdata[6].y.push(parseInt(data[this.rawselect]));
        this.pdata[6].y.shift();        

        if(this.firstLight) {
          this.firstLight = false;
          this.legFilter = data.legFilter;
          this.elbFilter = data.elbowFilter;
        }
        
      },
      sendCmd(cmd, val) {
        axios.get('http://'+this.target+'/cmd?cmd=' + cmd + '&val=' + val).then(response => {
          console.log(response);
        })
      }

    }
  }
</script>

<style scoped>
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }
</style>